import {
  AfterViewInit,
  DestroyRef,
  Directive,
  ElementRef,
  inject,
  input,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { fromEvent, merge } from "rxjs";

@Directive({
  standalone: true,
  selector: "[contentVisibleHoverUi]",
})
export class ContentVisibleHoverUi implements AfterViewInit {
  #destroyRef = inject(DestroyRef);
  readonly ref = inject(ElementRef);
  readonly childSelector = input<string | null>();

  get host(): HTMLElement {
    return this.ref.nativeElement;
  }

  ngAfterViewInit(): void {
    const children = this.childSelector();
    merge(
      fromEvent<MouseEvent>(this.host, "mouseenter"),
      fromEvent<MouseEvent>(this.host, "mouseleave")
    )
      .pipe(takeUntilDestroyed(this.#destroyRef))
      .subscribe(e => {
        if (children) {
          const target = this.host.querySelectorAll(
            children
          ) as NodeListOf<HTMLElement>;
          if (e.type === "mouseenter") {
            target.forEach(item => {
              item.style.visibility = "visible";
            });
          } else {
            target.forEach(item => {
              item.style.visibility = "hidden";
            });
          }
        } else {
          console.log("TODO://");
        }
      });
  }
}
