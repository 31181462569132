export function dedupItems<Model = any>(
  items: Model[],
  compareFn?: (a: Model, b: Model) => boolean
) {
  if (typeof compareFn !== "function") {
    compareFn = (a, b) => a === b
  }
  return items.reduce((results: Model[], b: Model) => {
    if (!results.some(x => compareFn(x, b))) {
      results.push(b);
    }
    return results;
  }, []);
}
